<template>
  <div class="post d-flex flex-column-fluid pt-10" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl text-center">
      <div v-if="token">
        <div class="py-2 px-2">
          <button
            @click="toSubmission('ktp')"
            class="btn btn-lg btn-success w-250px"
          >
            Pengajuan KTP
          </button>
        </div>
        <div class="py-2 px-2">
          <button @click="toSubmission('ktak')" class="btn btn-success w-250px">
            Pengajuan KTAK
          </button>
        </div>
        <div class="py-2 px-2">
          <button @click="toPersonalia" class="btn btn-success w-250px">
            Data Personalia
          </button>
        </div>
        <div class="py-2 px-2">
          <button class="btn btn-success w-250px">Laporan</button>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-danger mb-10" role="alert">
          Akses expired, silahkan kembali ke beranda terlebih dahulu
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseService from "@/services/base.service";

export default {
  data() {
    return {
      token: null,
    };
  },
  methods: {
    toSubmission(type) {
      const { token } = this.$route.query;
      this.$router.push({
        name: "service-submission-history",
        query: { type, token },
      });
    },
    toPersonalia() {
      const { token } = this.$route.query;
      this.$router.push({
        name: "service-personal",
        query: { token },
      });
    },
    async init() {
      const { token } = this.$route.params;
      if (token) {
        const personServices = new BaseService("services/person", token);
        const { data } = await personServices.getListData();
        this.token = data;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
